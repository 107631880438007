import * as React from "react";
import './styles.css';

export default function WhatsappLink() {
  return (
    <a className="whatsappLink fixed bottom-10 right-10 animate-pulse" style={{ zIndex: 100 }} target="_blank" href="https://api.whatsapp.com/send?phone=5592984625457&text=Olá,%20vim%20pelo%20site%20Med%20Academy.%20Pode%20me%20ajudar?">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5112 47.1203L16.3539 47.6198C19.8946 49.7208 23.954 50.8321 28.0934 50.8344H28.1025C40.8151 50.8344 51.1618 40.49 51.1673 27.7764C51.1691 21.615 48.7727 15.8215 44.4178 11.4634C40.0626 7.1055 34.2722 4.70399 28.1111 4.70147C15.3884 4.70147 5.04195 15.0443 5.03689 27.7576C5.03516 32.1142 6.25408 36.3571 8.56311 40.0284L9.11209 40.9006L6.78137 49.4091L15.5112 47.1203ZM0.118652 56L4.05561 41.6248C1.62736 37.4178 0.349768 32.6451 0.351631 27.7557C0.357752 12.46 12.806 0.0158081 28.1025 0.0158081C35.5255 0.0190014 42.4927 2.90815 47.7324 8.15144C52.9713 13.3951 55.8554 20.3652 55.8529 27.7782C55.8464 43.0731 43.3965 55.5196 28.1025 55.5196C28.1014 55.5196 28.1031 55.5196 28.1025 55.5196H28.0905C23.4465 55.5178 18.8828 54.353 14.8297 52.1426L0.118652 56Z" fill="white"/>
        <path d="M1.29255 27.7337C1.29255 32.4898 2.53561 37.0837 4.91364 41.1372L1.07637 55.027L15.2905 51.2978C19.1818 53.4597 23.6136 54.5406 28.0994 54.5406C42.854 54.5406 54.9062 42.5424 54.9062 27.7878C54.9062 20.5997 52.0959 13.898 47.0696 8.81762C41.9892 3.79134 35.2875 0.980946 28.0994 0.980946C13.3448 0.980946 1.29255 12.9792 1.29255 27.7337Z" fill="url(#paint0_linear_4710_965)"/>
        <path d="M0.331408 27.7122C0.331408 32.6388 1.61904 37.3975 4.08235 41.5963L0.107471 55.9842L14.8313 52.1213C18.8622 54.3607 23.4529 55.4803 28.0995 55.4803C43.3832 55.4803 55.8677 43.0519 55.8677 27.7682C55.8677 20.3223 52.9565 13.3803 47.75 8.11775C42.4875 2.91122 35.5454 4.95911e-05 28.0995 4.95911e-05C12.8159 4.95911e-05 0.331408 12.4285 0.331408 27.7122ZM9.12091 40.8685L8.56107 40.0287C6.26572 36.3338 5.03407 32.079 5.03407 27.7682C5.03407 15.0038 15.3911 4.70272 28.0995 4.70272C34.2578 4.70272 40.0801 7.11004 44.3909 11.4208C48.7577 15.7876 51.165 21.6099 51.165 27.7682C51.165 40.4766 40.8079 50.8337 28.0995 50.8337C23.9567 50.8337 19.8699 49.714 16.3429 47.5866L15.5031 47.0827L6.76958 49.3781L9.12091 40.8685Z" fill="url(#paint1_linear_4710_965)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.1695 16.1689C20.6501 15.0142 20.1034 14.9911 19.6092 14.9709C19.2051 14.9536 18.7423 14.9546 18.2804 14.9546C17.8181 14.9546 17.0668 15.1283 16.4316 15.8221C15.7958 16.516 14.0044 18.1933 14.0044 21.6047C14.0044 25.0165 16.4893 28.3129 16.8357 28.776C17.1824 29.2383 21.6326 36.4627 28.6803 39.2421C34.5377 41.5518 35.7296 41.0923 37.0009 40.9768C38.2723 40.8612 41.1033 39.2998 41.6811 37.6807C42.259 36.0618 42.259 34.6742 42.0856 34.3841C41.9122 34.0951 41.45 33.9217 40.7565 33.575C40.063 33.2282 36.6542 31.5506 36.0184 31.3194C35.3828 31.0883 34.9205 30.9727 34.4582 31.6668C33.9959 32.3603 32.6679 33.9217 32.2632 34.3841C31.8588 34.8475 31.4543 34.9053 30.7608 34.5585C30.0673 34.2107 27.8341 33.4793 25.1849 31.1172C23.1236 29.2794 21.732 27.0098 21.3273 26.3157C20.9228 25.6222 21.2841 25.2466 21.6319 24.9009C21.9432 24.5902 22.3254 24.0915 22.6721 23.6866C23.0181 23.2817 23.1337 22.9927 23.3648 22.5304C23.5959 22.0673 23.4804 21.6625 23.3071 21.3157C23.1337 20.969 21.7861 17.5399 21.1695 16.1689Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_4710_965" x1="27.9884" y1="55.027" x2="27.9884" y2="0.980946" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1AA331"/>
        <stop offset="1" stopColor="#60D66A"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4710_965" x1="27.9851" y1="55.9842" x2="27.9851" y2="4.95911e-05" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F9F9F9"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        </defs>
      </svg>
    </a>
  )
}