/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import InputMask from 'react-input-mask';
// import Button from '@mui/material/Button';
// import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import strapiURL, { leadToken } from '../../../../lib/lib';

interface FormProps {
  raioXForm?: boolean,
  id?: string
  emailValue: string,
  setEmail: any,
}

export default function FormComponent({ raioXForm, id }: FormProps) {
  const [nome, setNome] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefone, setTelefone] = React.useState('');
  const [residenciaMedica, setResidenciaMedica] = React.useState('');
  const [enabledBtn, setEnabledBtn] = React.useState(false);

  function validarTelefone(telefone: string): boolean {
    const regex = /^\(\d{2}\) \d{5}-\d{4}$/;
    return regex.test(telefone);
  }

  async function handleChange(elementToChange: string, change: string) {
    switch (elementToChange) {
      case 'nome':
        setNome(change);
        break;
      case 'email':
        setEmail(change);
        break;
      case 'telefone':
        change = change.substring(0, 15)
        setTelefone(change);
        break;
      case 'residencia':
        setResidenciaMedica(change);
        break;
      default:
        break;
    }
  }

  async function handleClick() {
    const postLeadReq = axios.create({
      baseURL: strapiURL,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postLeadReq.interceptors.request.use((config: any) => {
      const headers = {
        // 'Content-Type': 'application/json',
        'Authorization': leadToken,
      }

      return { ...config, headers }
    });

    
    const req = await postLeadReq.post('/api/leads', {
      nome,
      email,
      telefone,
      residencia_de_interesse: residenciaMedica,
    });
    console.info(req);
  }

  useEffect(() => {
    if (nome.length > 8 && /^\S+@\S+\.\S+$/.test(email) && telefone.length === 15 && validarTelefone(telefone) && residenciaMedica.length > 4) {
      setEnabledBtn(true);
    } else {
      setEnabledBtn(false);
    }
  },[nome, email, telefone, residenciaMedica])

  return (
    <div id={id ? id : 'formComponent'} style={raioXForm ? { borderBottom: '1px solid rgba(214, 226, 235, 0.80)' } : {}}>
      <Box
        sx={
        { 
          maxWidth: { xs: '100%', md: 1026 },
          marginRight: 'auto',
          marginLeft: 'auto',
          px: { xs: 3, md: 0 },
          pt: raioXForm ? { xs: 9, md: 32} : { xs: 10 },
          pb: { xs: 6.5, md: 19},
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 10, md: 0 }
        }}
      >
        {
          raioXForm === true
          ?
          (<Typography 
              component="h1"
              variant='h4'
              color='secondary'
            >
              Conheça nosso 
            <br />
            <Typography
              variant='h4'
              fontSize={{ xs: 45, md: 64 }}
              color={'#9865E0'}
            >
              Raio X
            </Typography>
            <div className='max-w-[387px] flex flex-col gap-[24px] desktop:gap-[31px] mt-8'>
              <Typography
                variant='subtitle1'
                fontSize={{ xs: 16, md: 16 }}
                color={'#000'}
              >
                Vai fazer a prova do CERMAM e quer entender melhor sobre o processo seletivo, notas de corte dos anos anteriores, distribuição de vagas e muito mais?
              </Typography>
              <Typography
                variant='h4'
                fontSize={{ xs: 16, md: 16 }}
                color={'#000'}
              >
                Fizemos um dossiê completo da banca. <br /> Aqui você encontra todas as informações concentradas para agregar ainda mais na sua aprovação!
              </Typography>
            </div>
          </Typography>)
          : (
            <Typography
              fontSize={{ xs: 32, md: 42 }}
              lineHeight={'155%'}
              color='secondary'
              fontWeight={600}
              maxWidth={420}
            >
              Provas Anteriores
              <br />
              do CERMAM
              <br />
              <br />
              <Typography
                fontSize={{ xs: 16, md: 24 }}
                color='black'
                fontWeight={500}
              >
                Preencha o formulário abaixo e tenha acesso às provas antigas.
              </Typography>
            </Typography>
          )
        }

        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            maxWidth: { xs: '100%', md: 552 }
          }}
          typography={{
            color: 'black'
          }}
        >
          {
            raioXForm ? 
              (
                <Typography
                  fontSize={32}
                  fontWeight={500}
                  lineHeight={'150%'}
                  color={'#281247'}
                >
                  Receba o <span style={{ color: '#9865E0', fontWeight: 700 }}>RAIO X</span> do CERMAM
                  <br />
                  <br />
                  <Typography
                    variant='subtitle2'
                    color='black'
                  >
                    É só preencher o formulário abaixo!
                  </Typography>
                </Typography>
              )
            :
            ''
          }
          <Box
             sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: { xs: '100%', md: 552 },
              color: 'black'
            }}
          >
            <Box>
              <Typography
                variant='body2'
                fontWeight={500}
                mb={1}
              >
                Nome
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                value={nome}
                style={{ maxWidth: '100vw' }}
                placeholder='Nome Completo'
                onChange={(e) => handleChange('nome', e.target.value)}
              >
              </TextField>
            </Box>

            <Box>
              <Typography
                variant='body2'
                fontWeight={500}
                mb={1}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                style={{ maxWidth: '100vw' }}
                value={email}
                placeholder='seuemail@gmail.com'
                onChange={(e) => handleChange('email', e.target.value)}
              >
              </TextField>
            </Box>

            <Box>
              <Typography
                variant='body2'
                fontWeight={500}
                mb={1}
              >
                Telefone
              </Typography>
              <InputMask
        mask="(99) 99999-9999"
        value={telefone}
        onChange={(e) => handleChange('telefone', e.target.value)}
      >
        {() => (
          <TextField
            fullWidth
            variant='outlined'
            style={{ maxWidth: '100vw' }}
            type='text'
            placeholder='(92) 99294-9999'
          />
        )}
      </InputMask>
            </Box>

            <Box>
              <Typography
                variant='body2'
                fontWeight={500}
                mb={1}
              >
                Residência Médica do seu interesse
              </Typography>
              <TextField
                placeholder='Cardiologia, Dermatologia, Pediatria...'
                fullWidth
                variant='outlined'
                style={{ maxWidth: '100%' }}
                value={residenciaMedica}
                onChange={(e) => handleChange('residencia' ,e.target.value)}
              >
              </TextField>
            </Box>
            
            <Button
              sx={{
                color: 'white',
                fontSize: 14,
                backgroundColor: !enabledBtn === true ? 'gray' : '#9865E0',
                '&:hover': {
                  backgroundColor: '#5f469c'
                }
              }}
              style={{ maxWidth: '100vw' }}
              size='medium'
              disabled={!enabledBtn}
              onClick={handleClick}
            > 
              <a href= {!raioXForm ? 'https://drive.google.com/drive/folders/1Kasj8b7ghScKasYojcIqqSKT6-gdvD21?usp=sharing' : 'https://drive.google.com/file/d/1XXey5aVQbkdEgpVbrksNIBqRR1Vt_6Lu/view?usp=sharing'} target="_blank" download='Provas Antigas CERMAM'>
                {!raioXForm ? 'Baixar Provas Antigas CERMAM' : 'Receber Raio X do CERMAM'}
              </a>
            </Button>

          </Box>

        </Box>
      </Box>
    </div>
  )
}